import React from "react"
import get from 'lodash/get'
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from 'styled-components'
import BgImage from '../images/banner-images/portfolio-banner.jpg'
import Banner from "../components/banner"
import PortfolioTeaser from '../components/portfolioTeaser'


class PortfolioPage extends React.Component {
  render() {
    const portfolioItems = get(this, 'props.data.allNodePortfolioItem.edges')  


     return (
      <Layout>
          <Seo title="Portfolio | Latest projects completed by ThowardMedia" description="Check out the latest projects completed by ThowardMedia." />

          <Banner title="Portfolio" image={BgImage} />

          <IntroWrapper>
            <p>Browse through recently completed projects.</p>
          </IntroWrapper>

           <Latest>
               <TeaserList>
                  {portfolioItems.map(({ node }) => {
                    return (
                      <TeaserListItem key={node.path.alias}>
                        <PortfolioTeaser portfolioItem={node} />
                      </TeaserListItem>
                    )
                  })}
                </TeaserList>
            </Latest>  

        </Layout>
    )
  }
}

export default PortfolioPage

const Latest = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  h2.title {
    margin-top: 100px;
  }
`

const TeaserList = styled.ul`
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 10px;
`
const TeaserListItem = styled.li`
  list-style: none;
  flex: 100%;
  margin: 10px;

  h2:last-child {
    margin-bottom: 15px;
  }

  &:last-child {
    flex-grow: 0;
  }

  @media(min-width: 575px) {
    flex: 1 0 31.5%;
  }
`

const IntroWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  color: #fff;  
  text-align: center;
  font-size: 20px;
  
  @media(max-width: 980px) {
    padding: 0 20px;
  }
`


export const pageQuery = graphql`
  query PortfolioItemsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allNodeTestimonial {
      edges {
        node {
          id
          body {
            processed
          }
        }
      }
    }
    allNodePortfolioItem(sort: {fields: field_date_complete, order: DESC}) {
      edges {
        node {
          title
          created(formatString: "dddd Do, MMMM YYYY")
          field_date_complete(formatString: "dddd Do, MMMM YYYY")
          path {
            alias
          }
          relationships {
            field_featured_image {
              localFile {
                publicURL
                childImageSharp {
                  # Specify a fixed image and fragment.
                  # The default width is 400 pixels
                  fixed(width: 700, height: 500) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            } 
          }
        }
      }
    }
  }
`