import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

function PortfolioTeaser({ portfolioItem }) {

  return(
    <Container className={`article-teaser`}>
      <Link to={`${portfolioItem.path.alias}`}>
        <div className="image-wrapper">
          <img loading="lazy" src={portfolioItem.relationships.field_featured_image.localFile.childImageSharp.fixed.src} 
          alt={portfolioItem.relationships.field_featured_image.alt} />
        </div>
      </Link>
    </Container>
  );
}

export default PortfolioTeaser;
 
const Container = styled.div`

background: #fff;
display: flex;
border-radius: 0 0 5px 5px;

 a {
   text-decoration: none;
 }

 .image-wrapper {
    overflow: hidden;
    border-bottom: 2px #272f32 solid;
    
    img {
      display: block;
      height: auto;
      transform: scale(1);
      transition: transform .2s; 
    }
 }

 &:hover {
   img {
        transform: scale(1.2);
      }
 }
`